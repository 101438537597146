<template>
  <ul>
    <li class="full-text">
      <router-link class="link" to="/">Josh Halvorson</router-link>
    </li>
    <li class="short-text">
      <router-link class="link" to="/">Josh</router-link>
    </li>
    <div class="right">
      <li class="full-text">
        <router-link class="link" to="/projects">Dev Projects</router-link>
      </li>
      <li class="short-text">
        <router-link class="link" to="/projects">Dev</router-link>
      </li>
      <li class="full-text">
        <router-link class="link" to="/astrophotgraphy"
          >Astrophotography</router-link
        >
      </li>
      <li class="short-text">
        <router-link class="link" to="/astrophotgraphy">Astro</router-link>
      </li>
    </div>
  </ul>
</template>

<script>
export default {
  name: "Header",
};
</script>

<style scoped>
.short-text {
  display: none;
}
@media screen and (max-width: 430px) {
  .short-text {
    display: inline-block;
  }
  .full-text {
    display: none;
  }
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #333;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  z-index: 100;
  width: 100%;
}

li {
  float: left;
  color: white;
}

.link {
  display: block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

.link:hover {
  background-color: #111;
}

.right {
  float: right;
}
/* .header {
  background: #ffffff00;
  color: rgb(0, 0, 0);
  margin-bottom: 80px;
  width: 100%;
  height: auto;
  display: flex;
}
.one,
.two {
  width: 50%;
  height: 100px;
  margin: 10px;
}

.two {
  width: 100%;
  height: auto;
  display: flex;
}

#leftLink {
  width: 50%;
  height: 100px;
  text-align: right;
  background: red;
}

#rightLink {
  width: 50%;
  height: 100px;
  text-align: right;
  background: chartreuse;
}

#nav a {
  font-weight: bold;
  color: rgb(0, 0, 0);
  text-decoration: none;
}

#nav a:hover {
  cursor: pointer;
} */
</style>