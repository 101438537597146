import Vue from 'vue';
import App from './App.vue';
import router from './router';
import VueCarousel from 'vue-carousel';
import VueImageLoader from '@kevindesousa/vue-image-loader'
import Vuex from 'vuex';

Vue.use(Vuex)
Vue.use(VueImageLoader)
Vue.config.productionTip = false;
Vue.use(VueCarousel);

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
