<template>
  <div class="content">
    <div class="imgDiv">
      <img id="img" :src="require(`@/assets/static/images/me.png`)" />
    </div>
    <div class="descDiv">
      <h1>Hello! I'm Josh Halvorson.</h1>
      <br />
      <p>
        Android Developer currently working at Accolade helping people get the healthcare they need!
      </p>
      <br />
      <br />
      <br />
      <p>
        I'm a Seattle native and have been learning software
        development since about 2015, switched to Android dev specifically in
        2018 and haven't looked back. As far as my career goes I've done some TA
        work for the boot camp I went to, Lambda School, where I helped students
        who were behind on the Android curriculum catch up by leading lessons
        and doing code review, that's where I really solidified my knowledge of
        Android and confidence in myself. Then after that I started at Billseye
        as an Android dev working on a really cool project with a great team. I worked
        there for over a year before I had an amazing opportunity come to me at a company called
        Accolade to work in the Personalized Healthcare field. That was almost a dream 
        scenario for me, so I jumped on it and am currently working there as a Software Engineer!
      </p>
      <br />
      <p>
        This site also has my astrophotography images, which is one of my
        hobbies. I love taking pictures of Galaxies or Nebulae because it's
        kinda like looking into the past. Some of the pictures I've taken are of
        objects that are millions of light years away, and the light just so
        happened to end its journey in my camera sensor to be immortalized in a
        picture, and I think that's the coolest thing.
      </p>
      <br />
      <p>
        Feel free to browse my image gallery or take a look at my developer
        projects, those are cool too.
      </p>
      <br />
      <p>
        Contact me at:
        <a :href="`mailto:josh.halvorson20@gmail.com`" id="email"
          >josh.halvorson20@gmail.com</a
        >
      </p>
      <p>
        <a :href="`https://github.com/JoshHalvorson`" target="_blank">My GitHub</a>
      </p>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home",
  components: {},
};
</script>

<style scoped>
.content {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 80%;
  display: flex;
}

@media (max-width: 1044px) {
  .content {
    flex-direction: column;
    width: 90%;
  }

  #img {
    height: 400px;
    margin-top: 50px;
  }
}

@media (min-width: 1044px) {
  #img {
    height: 600px;
  }
}

.descDiv {
  flex: 1;
  text-align: center;
  margin: auto;
}

.descDiv h1 {
  font-size: 2.4em;
}

.descDiv p {
  font-size: 1.4em;
  text-align: left;
}

.imgDiv {
  flex: 1;
  text-align: left;
  margin: auto;
}

a {
  text-decoration: none;
  color: rgb(68, 156, 192);
}
</style>
